<template>
  <div class="the-navbar__user-meta flex items-center relative"
       v-if="activeUser.id">

    <div v-if="!isMobile" class="text-right leading-tight cursor-pointer"
         @click="show = !show">
      <p class="font-semibold" v-text="`${activeUser.name} ${activeUser.family}`"></p>
      <!-- <small>Available</small> -->
    </div>

    <div class="con-img cursor-pointer" :class="!isMobile ? 'ml-3' : ''">
      <img v-if="activeUser.avatar"
           key="onlineImg"
           :src="activeUser.avatar"
           alt="user-img"
           width="40"
           height="40"
           class="profile-img rounded-full shadow-md cursor-pointer block"
           @click="show = !show"/>
    </div>

    <custom-drop-down :list="list"
                      :show="show"
                      :fix-to-right="isMobile"
                      @closed="show = false"
                      @logout="logout"/>

    <!--<vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">
      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary ">
            <router-link class="text-white hover:text-white" :to="{name: 'Profile'}">
              <feather-icon icon="UserIcon" svgClasses="w-4 h-4"/>
              <span class="ml-2">{{ $t("profile.title") }}</span>
            </router-link>
          </li>

          &lt;!&ndash; <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MailIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Inbox</span>
          </li> &ndash;&gt;

          &lt;!&ndash; <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="CheckSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Tasks</span>
          </li> &ndash;&gt;

          &lt;!&ndash; <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="MessageSquareIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Chat</span>
          </li> &ndash;&gt;

          &lt;!&ndash; <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white">
            <feather-icon icon="HeartIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">Wish List</span>
          </li> &ndash;&gt;

          <vs-divider class="m-1"/>

          <li
            class="flex py-2 px-4 cursor-pointer text-danger hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4"/>
            <span class="ml-2">{{ $t("logout") }}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>-->
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import CustomDropDown from '@/components/customDropDown/customDropDown'
import {logoutUser} from '@/http/requests/auth'

export default {
  components: {CustomDropDown},
  computed: {
    ...mapGetters({
      activeUser: 'auth/getActiveUser'
    }),
    mobileMode () {
      return window.innerWidth > 767
    },
    isMobile () {
      return this.$store.state.windowWidth < this.$store.state.mobileScreen
    },
  },
  data () {
    return {
      show: false,
      list: [
        {
          i18n: 'profile.title',
          type: 'link',
          icon: 'icon-user',
          iconPack: 'feather',
          route: {name: 'Profile'}
        },
        {
          i18n: 'logout',
          icon: 'icon-log-out',
          iconPack: 'feather',
          color: 'danger',
          event: 'logout'
        }
      ]
    }
  },
  methods: {
    logout () {
      logoutUser().then(() => {
        this.$store.dispatch('auth/logout')
      })

      // This is just for demo Purpose. If user clicks on logout -> redirect
      /*this.$router.push('/login').catch(() => {
      })*/
    }
  }
}
</script>

<style lang="scss" scoped>
.the-navbar__user-meta {
  position: relative;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .profile-img {
    object-fit: cover;
  }
}
</style>
