<template>
  <div class="custom-drop-down" :class="{'fix-to-right': fixToRight, 'fix-to-left': !fixToRight}">
    <transition :css="false"
                @before-enter="beforeEnterRow"
                @enter="enterRow"
                @leave="leaveRow">
      <div class="custom-drop-down-wrapper"
           v-show="show">

        <ul>
          <li class="drop-down-list"
              :class="item.color ? `text-${item.color}` : ''"
              v-for="(item, item_index) in list"
              :key="item_index"
              @click="handleClick(item)">

            <div v-if="item.type === 'html'"
                 v-html="item.html"/>

            <router-link v-else-if="item.type === 'link'"
                         :to="item.route">
              <vs-icon :icon="item.icon"
                       :icon-pack="item.iconPack"/>

              <span>
                {{ $t(item.i18n) || item.label }}
              </span>
            </router-link>

            <div v-else-if="item.type === 'img'">
              <img :src="item.src" :class="item.imgClass ? item.imgClass : ''">
              {{ $t(item.i18n) || item.label }}
            </div>

            <div v-else>
              <vs-icon class="mr-1"
                       :icon="item.icon"
                       :icon-pack="item.iconPack"/>

              <span>
                {{ $t(item.i18n) || item.label }}
              </span>
            </div>
          </li>
        </ul>
      </div>
    </transition>

    <div class="custom-drop-down-overlay"
         @click="$emit('closed')"
         v-if="show"></div>
  </div>
</template>

<script>
import gsap from 'gsap'

export default {
  name: 'customDropDown',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array,
      default: () => { return [] }
    },
    fixToRight: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick (item) {
      if (item.hasOwnProperty('event')) this.$emit(item.event, item)
      else this.$emit('clicked', item)
      this.$emit('closed')
    },
    beforeEnterRow (el) {
      el.style.opacity = 0
      el.style.transform = 'translate(0px, -30px)'
    },
    enterRow (el, done) {
      gsap.to(el, {
        opacity: 1,
        y: '0',
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    },
    leaveRow (el, done) {
      gsap.to(el, {
        opacity: 0,
        y: '-30px',
        delay: el.dataset.index * 0.35,
        onComplete: done
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-drop-down {

  &.fix-to-right {

    .custom-drop-down-wrapper {
      left: 0;
    }
  }

  &.fix-to-left {

    .custom-drop-down-wrapper {
      right: 0;
    }
  }

  .custom-drop-down-wrapper {
    position: absolute;
    top: 40px;
    background: #333333;
    padding: 10px;
    //right: 0;
    min-width: 125px;
    border-radius: 5px;
    border: 1px solid #555555;
    z-index: 12000;

    ul {

      li.drop-down-list {
        cursor: pointer;
        line-height: 30px;
        border-bottom: 1px solid #555555;

        &:last-child {
          border: none;
        }

        div {
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .custom-drop-down-overlay {
    //cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 10000;
  }
}
</style>
