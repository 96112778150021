<template>
  <div class="w-full flex the-vertical-nav-menu__user-meta" v-if="activeUser.id">
<!--
    <vs-row
      class="w-full"
      vs-type="flex"
      vs-justify="center">
      <vs-col class="w-3/5">
        <img v-if="activeUser.avatar"
             key="onlineImg"
             :src="activeUser.avatar"
             alt="user-img"
             :width="reduce ? 50 : 75"
             class="rounded-full mx-auto shadow-md cursor-pointer block"/>
      </vs-col>
    </vs-row>

    <vs-row
      v-show="!reduce"
      class="w-full"
      vs-type="flex"
      vs-justify="center">
      <vs-col class="md:w-3/5">
        <div class="text-center leading-tight py-3">
          <p class="font-semibold" v-text="`${activeUser.name} ${activeUser.family}`"></p>
        </div>
      </vs-col>
    </vs-row>-->

    <span style="min-width: 50px">
      <router-link :to="{name: 'Profile'}">
      <img v-if="activeUser.avatar"
           key="onlineImg"
           :src="activeUser.avatar"
           alt="user-img"
           :width="50"
           :height="50"
           class="rounded-full shadow-md cursor-pointer block"/>
      </router-link>
    </span>
    <span class="py-1 px-2">
      <span class="text-left leading-tight truncate block py-1" v-if="!verticalNavMenuItemsMin">
        <p class="font-semibold" v-text="`${activeUser.name} ${activeUser.family}`"></p>
      </span>
      <span class="logout-button text-left text-danger text-xs leading-tight truncate" v-if="!verticalNavMenuItemsMin">
        <log-out-icon size="17"/>
        <p class="font-semibold inline-flex pl-1"
           @click="logout" v-text="$t('logout')"></p>
      </span>
    </span>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {LogOutIcon} from 'vue-feather-icons'

export default {
  name: 'ProfileBox',
  props: [
    'reduce',
    'verticalNavMenuItemsMin'
  ],
  components: {
    LogOutIcon
  },
  computed: {
    ...mapGetters({
      activeUser: 'auth/getActiveUser'
    })
  },
  methods: {
    logout () {
      this.$store.dispatch('auth/logout')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      this.$router.push('/login').catch(() => {})
    }
  }
}
</script>

<style scoped>
  .logout-button {
    display: inline-flex;
    vertical-align: middle;
    cursor: pointer;
  }
</style>
